import axios from 'axios';
import { BACK_SERVER_URL } from '../config/server';
import { LOGGED_IN_USER_DATA, LOGIN_USER, SET_PROFILE, USER_SIGNUP, FORGET_PASSWORD, REFRESH_TOKEN } from './types';
// import { useNavigate } from 'react-router-dom';
// import { useEffect } from 'react';

// Takes object containing user email and password as param
// Returns an object containing token and user role if email pass is correct
// Throws error 401 if not authorized


export const loginUser = (data) => {

    let url = `${BACK_SERVER_URL}api/users/login`;

    const request = axios.post(url, data)
        .then(response => response.data);
      
       
    return {
        type: LOGIN_USER,
        payload: request
    }
}

// Returns an object containing logged in user details
// Used in SIGNIN page after completing the signing process to save logged in user details in host for later requests
export const getLoggedInUser = () => {

    let url = `${BACK_SERVER_URL}api/users/me`;

    const request = axios.get(url)
        .then(response => response.data);

    return {
        type: LOGGED_IN_USER_DATA,
        payload: request
    }
}

export const signup = (data) => {

     let url = `${BACK_SERVER_URL}api/users/signup`;
 
    const request =  axios.post(url, data)
    .then(
            
             response => response.data
             
            )
            
        // .catch(error=> error.data)

    return {
        type: USER_SIGNUP,
        payload: request
}}

// no email involved, only phone number and minimum demographics
export const Minimal_Signup = (data) => {

    let url = `${BACK_SERVER_URL}api/users/signup/minimal-signup`;

   const request =  axios.post(url, data)
   .then(
           
            response => response.data
            
           )
           
       // .catch(error=> error.data)

   return {
       type: USER_SIGNUP,
       payload: request
}}

export const Minimal_Login = (data) => {

    let url = `${BACK_SERVER_URL}api/users/login/minimal-login`;

   const request =  axios.post(url, data)
   .then(
           
            response => response.data
            
           )
           
       // .catch(error=> error.data)

   return {
       type: LOGIN_USER,
       payload: request
}}

export const signupThroughAdmin = (data) => {

    let url = `${BACK_SERVER_URL}api/users/signup/throughAdmin`;

   const request =  axios.post(url, data)
   .then(
           
            response => response.data
            
           )
           
       // .catch(error=> error.data)

   return {
       type: USER_SIGNUP,
       payload: request
}}

export const signupPhone = (phoneData) => {

    let url = `${BACK_SERVER_URL}api/users/signup`;

   const request =  axios.post(url, {phoneData:phoneData})
   .then(
           
            response => response.data
            
           )
           
       // .catch(error=> error.data)

   return {
       type: USER_SIGNUP,
       payload: request
}}

export const signupEmail = (emailData) => {

    let url = `${BACK_SERVER_URL}api/users/signup`;

   const request =  axios.post(url, {emailData:emailData})
   .then(
           
            response => response.data
            
           )
           
       // .catch(error=> error.data)

   return {
       type: USER_SIGNUP,
       payload: request
}}

export const signupOTP = (code) => {

    let url = `${BACK_SERVER_URL}api/users/signup`;

   const request =  axios.post(url, {otpCode:code})
   .then(
           
            response => response.data,
            
          
           )
           
        //.catch(error=> error.data)

   return {
       type: USER_SIGNUP,
       payload: request
}}

export const signupOTPAgain = (againUserr ) => {

    let url = `${BACK_SERVER_URL}api/users/signup`;
//console.log('again code api react->', againUser)
   const request =  axios.post(url,{againUser: againUserr})
    .then(
           
             response => response.data
            
            )
           
       // .catch(error=> error.data)

   return {
       type: USER_SIGNUP,
       payload: request
}}

   
export const SignupGoogle =  (accessToken, referredBy) =>{
    
    let signupGoogleData ={}

    if(referredBy != null && referredBy != undefined && referredBy !=''){
        signupGoogleData={
            accessToken: accessToken,
            referredBy: referredBy
        }
    }else{
        signupGoogleData={
            accessToken: accessToken
        }
    }
    
    let url = `${BACK_SERVER_URL}api/users/signup`;
    
    console.log('google token in api ->', signupGoogleData)

    

    
    const request =  axios.post(url, {googleAccessToken: signupGoogleData})
    .then(response => response.data,
       
        )
      
                
    return{
        type: USER_SIGNUP,
        payload: request,
        
    }
}

export const SignupTiktok =  (accessToken) =>{
    
   

    
    
    let url = `${BACK_SERVER_URL}api/users/signup`;
    
    console.log('tiktok token in api ->', accessToken)

    
    
    
    const request =  axios.post(url, {tiktokAccessToken: accessToken})
    // .then(response => response.data,
       
    //     )
      
                
    return{
        type: USER_SIGNUP,
        payload: request,
        
    }
}

export const signupFacebook =  (accessToken, referredBy) =>{
    
    let signupFBData={}

    if(referredBy != null && referredBy != undefined && referredBy !=''){
        signupFBData={
            accessToken: accessToken,
            referredBy: referredBy
        }
    }else{
        signupFBData={
            accessToken: accessToken
        }
    }

    let url = `${BACK_SERVER_URL}api/users/signup`;
    
    //console.log('facebook access token in api ->', accessToken)

    

    
    const request =  axios.post(url, {facebookAccessToken: signupFBData})
    
     .then(response => response.data,
        // history('/login')
         )
                
    return{
        type: USER_SIGNUP,
        payload: request,
        
    }
} 

export const loginGoogle = (accessToken)=>{
    let url = `${BACK_SERVER_URL}api/users/login`;
    
    console.log('google token in api login ->', accessToken)

    

    
   // const request =  axios.post(url, {googleAccessToken: accessToken})
    const request =  axios.post(url, {oauthAccessToken1: accessToken})
    
    .then(response =>response.data)
        
   
        return{
            type: LOGIN_USER,
            payload: request,
            
        }
}

export const loginFacebook = (accessToken)=>{
    let url = `${BACK_SERVER_URL}api/users/login`;
    
    console.log('facebook token in api login ->', accessToken)

    

    
   // const request =  axios.post(url, {googleAccessToken: accessToken})
    const request =  axios.post(url, {oauthAccessToken2: accessToken})
    
    .then(response =>response.data)
        
   
        return{
            type: LOGIN_USER,
            payload: request,
            
        }
}


// Takes object containing email as param
// Used to send recover password link on email
export const forgetPassword = (data) => {

    let url = `${BACK_SERVER_URL}api/users/forgetPassword`;

    const request = axios.post(url, data)
        .then(response => response.data);
        

    return {
        type: FORGET_PASSWORD,
        payload: request
    }
}


// Used in app.js file to refresh token when one expires by sending refresh access token
// Returns new access token 
export const refreshToken = (data) => {

    let url = `${BACK_SERVER_URL}api/users/refreshAccessToken`;

    const request = axios.post(url, data)
        .then(response => response.data);

    return {
        type: REFRESH_TOKEN,
        payload: request
    }
}


// Used to set RESPONDENT profile after signup
// Used in Create Profile section under pages folder
export const setProfile = (profileData) => {

    let url = `${BACK_SERVER_URL}api/users/setProfile`;

    const request = axios.post(url, profileData)
        .then(response => response.data);

    return {
        type: SET_PROFILE,
        payload: request
    }
}

